<template>
  <fw-layout mobile-ready :back-to-enable="false" :main-sidebar="false" :footer="true">
    <template #header-nav>
      <div class="inline-block">
        <BlockHeaderSwitchManage :has-permissions="showHeaderSwitchManage" />
      </div>
    </template>
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-4 mb-10" version="v2" />
      <fw-panel :title="$t('quickAccess')" class="my-5">
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
          <button-quick-access
            v-if="lastEdition && lastEdition.key"
            :label="lastEdition.name"
            :title="$t('openLastEdition')"
            @clicked="goToView('last-edition', lastEdition.key)"
          />
          <button-quick-access
            v-if="lastTournament && lastTournament.key"
            :label="lastTournament.title"
            :title="$t('openLastTournament')"
            @clicked="goToView('last-tournament', lastTournament.key)"
          />
          <button-quick-access
            v-if="false"
            :label="$t('management')"
            :title="$t('configurations')"
            icon="settings"
            @clicked="$router.push('/manage/configurations')"
          />
          <button-quick-access
            v-if="userPermissions.isGlobalManager"
            :label="$t('audit')"
            :title="$t('activity')"
            icon="activity"
            @clicked="$router.push('/manage/activity')"
          />
          <button-quick-access
            v-if="userPermissions.isGlobalManager"
            :label="$t('audit')"
            :title="$t('notifications')"
            icon="message-sent"
            @clicked="$router.push('/manage/notifications')"
          />
        </div>
      </fw-panel>
      <fw-panel :title="$t('editions')">
        <PanelManageEditions @view="goToView('edition', $event)" />
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import PanelManageEditions from '@/components/panels/manage/PanelManageEditions'
import BlockHeaderSwitchManage from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderSwitchManage'
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'

export default {
  components: {
    BlockHeaderSwitchManage,
    PanelUCIdHero,
    ButtonQuickAccess,
    PanelManageEditions,
  },

  data() {
    return {
      lastTournament: null,
      lastEdition: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    },
    showHeaderSwitchManage() {
      return this.userPermissions.isAnyManager
    },
  },

  mounted() {
    this.loadLocalLastTournament()
    this.loadLocalLastEdition()
  },

  methods: {
    // Utils
    goToView(page, key) {
      if (page === 'last-edition' || page === 'edition') {
        this.$router.push({ path: '/manage/edition/' + key + '/dashboard' })
      } else if (page === 'last-tournament') {
        this.$router.push({ path: '/manage/tournament/' + key + '/dashboard' })
      }
    },
    loadLocalLastTournament() {
      const lsLast = localStorage.getItem('last-tournament-open')
      if (lsLast) this.lastTournament = JSON.parse(lsLast)
      return false
    },
    loadLocalLastEdition() {
      const lsLast = localStorage.getItem('last-edition-open')
      if (lsLast) this.lastEdition = JSON.parse(lsLast)
      return false
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "tournament": "Torneio",
      "quickAccess": "Acesso rápido",
      "openLastTournament": "Abrir último torneio",
      "openLastEdition": "Abrir última edição",
      "createTournament": "Criar novo torneio",
      "openTournaments": "Torneios",
      "audit": "Auditoria",
      "tournaments": "Torneios",
      "people": "Pessoas",
      "activity": "Atividade",
      "notifications": "Notificações",
      "configurations": "Configurações",
      "management": "Gestão",
      "openEditions": "Edições",
      "editions": "Edições"
    },
    "en": {
      "tournament": "Tournament",
      "quickAccess": "Quick access",
      "openLastTournament": "Open last tournament",
      "openLastEdition": "Open last edition",
      "createTournament": "Create new tournament",
      "openTournaments": "See all tournaments",
      "audit": "Audit",
      "tournaments": "Tournaments",
      "people": "People",
      "activity": "Activity",
      "notifications": "Notifications",
      "configurations": "Configurations",
      "management": "Management",
      "openEditions": "Open editions",
      "editions": "Editions"
    }
  }
  </i18n>
