<template>
  <div>
    <LoadingPlaceholder v-if="loading" class="my-5" />
    <div v-else-if="editions.length" class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 mb-5">
      <CardEdition
        v-for="edition in editions"
        :key="edition.key"
        :edition="edition"
        @view="$emit('view', edition.key)"
      />
    </div>
    <fw-panel-info v-else type="basic">
      {{ $t('noeditions') }}
    </fw-panel-info>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardEdition from '@/components/cards/CardEdition'

export default {
  components: {
    LoadingPlaceholder,
    CardEdition,
  },

  data() {
    return {
      loading: false,
      editions: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.load()
  },

  methods: {
    async load() {
      this.loading = true
      try {
        let response = await this.api.managementGetEditions()
        this.editions = response.editions
      } finally {
        this.loading = false
      }
    },

    refresh() {
      this.load()
    },
  },
}
</script>

<i18n>
    {
      "pt": {
        "noeditions": "Sem edições a mostrar"
      },
      "en": {
        "noeditions": "No editions to show"
      }
    }
    </i18n>
